<template>
    <Container  title="Settings" :current-name="$options.name" :items="items">
        <h1>ตั้งค่าไรดีนะอิอิ</h1>
    </Container>

</template>

<script>
import Container from '../../components/Container'
export default {
    name:'Settings',
    data(){
        return {
            items:[
                {
                    key:'test',
                    to:'/user/settings/test',
                    value:'ทดสอบ'
                }
            ]
        }
    },
    components:{
        Container
    }
}
</script>